import { companies } from "./companies";

export interface Option {
  value: string;
  label: string;
  count?: number;
}

export interface OptionGroup {
  name: string;
  options: Option[];
}

export interface Filter {
  id: "stack" | "country" | "remote" | "seniority" | "companies" | "techStack";
  name: string;
  options: Option[];
  groups?: OptionGroup[]; // Optional groups for organized display
}

// Tech stack groups definition
const techStackGroups: OptionGroup[] = [
  {
    name: "Programming Languages",
    options: [
      { value: "javascript", label: "JavaScript" },
      { value: "typescript", label: "TypeScript" },
      { value: "python", label: "Python" },
      { value: "java", label: "Java" },
      { value: "golang", label: "Go" },
      { value: "rust", label: "Rust" },
      { value: "cpp", label: "C++" },
      { value: "swift", label: "Swift" },
      { value: "kotlin", label: "Kotlin" },
    ],
  },
  {
    name: "Frontend Frameworks",
    options: [
      { value: "react", label: "React" },
      { value: "vue", label: "Vue" },
      { value: "angular", label: "Angular" },
      { value: "svelte", label: "Svelte" },
      { value: "nextjs", label: "Next.js" },
    ],
  },
  {
    name: "Mobile Technologies",
    options: [
      { value: "reactnative", label: "React Native" },
      { value: "flutter", label: "Flutter" },
      { value: "ionic", label: "Ionic" },
      { value: "androidnative", label: "Android Native" },
      { value: "iosnative", label: "iOS Native" },
      { value: "xamarin", label: "Xamarin" },
    ],
  },
  {
    name: "Backend Frameworks",
    options: [
      { value: "nodejs", label: "Node.js" },
      { value: "django", label: "Django" },
      { value: "flask", label: "Flask" },
      { value: "spring", label: "Spring" },
      { value: "fastapi", label: "FastAPI" },
      { value: "express", label: "Express" },
    ],
  },
  {
    name: "Databases",
    options: [
      { value: "postgresql", label: "PostgreSQL" },
      { value: "mysql", label: "MySQL" },
      { value: "mongodb", label: "MongoDB" },
      { value: "redis", label: "Redis" },
    ],
  },
  {
    name: "Cloud & DevOps",
    options: [
      { value: "aws", label: "AWS" },
      { value: "azure", label: "Azure" },
      { value: "gcp", label: "GCP" },
      { value: "kubernetes", label: "Kubernetes" },
      { value: "docker", label: "Docker" },
      { value: "terraform", label: "Terraform" },
    ],
  },
  {
    name: "Tools",
    options: [
      { value: "git", label: "Git" },
      { value: "jenkins", label: "Jenkins" },
      { value: "graphql", label: "GraphQL" },
      { value: "rest", label: "REST" },
    ],
  },
];

export const filters: Filter[] = [
  {
    id: "country",
    name: "Location",
    options: [
      { value: "al", label: "Albania" },
      { value: "at", label: "Austria" },
      { value: "be", label: "Belgium" },
      { value: "bg", label: "Bulgaria" },
      { value: "hr", label: "Croatia" },
      { value: "cy", label: "Cyprus" },
      { value: "cz", label: "Czech Republic" },
      { value: "ch", label: "Switzerland" },
      { value: "dk", label: "Denmark" },
      { value: "ee", label: "Estonia" },
      { value: "fi", label: "Finland" },
      { value: "fr", label: "France" },
      { value: "de", label: "Germany" },
      { value: "gr", label: "Greece" },
      { value: "hu", label: "Hungary" },
      { value: "ie", label: "Ireland" },
      { value: "it", label: "Italy" },
      { value: "lv", label: "Latvia" },
      { value: "lt", label: "Lithuania" },
      { value: "lu", label: "Luxembourg" },
      { value: "mt", label: "Malta" },
      { value: "nl", label: "Netherlands" },
      { value: "pl", label: "Poland" },
      { value: "pt", label: "Portugal" },
      { value: "ro", label: "Romania" },
      { value: "sk", label: "Slovakia" },
      { value: "si", label: "Slovenia" },
      { value: "es", label: "Spain" },
      { value: "se", label: "Sweden" },
      { value: "gb", label: "UK" },
      { value: "rs", label: "Serbia" },
    ],
  },
  {
    id: "remote",
    name: "Remote",
    options: [{ value: "true", label: "Remote" }],
  },
  {
    id: "stack",
    name: "Role",
    options: [
      { value: "frontend", label: "Frontend" },
      { value: "backend", label: "Backend" },
      { value: "mobile", label: "Mobile" },
      { value: "sitereliability", label: "Site Reliability" },
      { value: "fullstack", label: "Fullstack" },
      { value: "dataengineering", label: "Data" },
      { value: "machinelearning", label: "Machine Learning" },
      { value: "other", label: "Other" },
    ],
  },
  {
    id: "seniority",
    name: "Seniority",
    options: [
      { value: "junior", label: "Junior" },
      { value: "mid-level", label: "Mid" },
      { value: "senior", label: "Senior" },
      { value: "staff", label: "Staff" },
      { value: "principal", label: "Principal" },
    ],
  },
  {
    id: "companies",
    name: "Company",
    options: companies.map((company) => ({
      value: company.id,
      label: company.name,
    })),
  },
  {
    id: "techStack",
    name: "Tech Stack",
    options: techStackGroups.flatMap(group => group.options), // Flatten for backward compatibility
    groups: techStackGroups, // Add grouped structure
  },
];
