import { ChevronUpIcon } from "@heroicons/react/20/solid";
import { Button } from "~/components/Button";
import { PaginatedJobsTable } from "~/features/jobs/CompanyTable";
import { Filters } from "~/features/landing/Filters";
import { JobBoardData } from "~/utils/fetchJobBoardData";
import { FAQ } from "~/components/FAQ";

interface JobBoardProps extends JobBoardData {
  hideFilter?: boolean;
  targetFilters?: Record<string, any>;
  authenticated?: boolean;
  alert?: React.ReactNode;
}

export function JobBoard({
  jobs,
  size,
  activeJobFilters,
  precomputedFilters,
  hideFilter,
  targetFilters,
  authenticated,
  alert,
}: JobBoardProps) {
  if (!jobs || !size) {
    return null;
  }

  const targetFilterQuery = new URLSearchParams(targetFilters).toString();
  const filterRoute = targetFilterQuery ? `/?${targetFilterQuery}` : "/";

  return (
    <>
      {hideFilter ? (
        <div className="flex flex-row w-full gap-4">
          <div className="m-0 text-gray-300">{jobs.count} jobs found</div>
          <Button to={filterRoute} variant="secondary" className="mt-6 ml-auto">
            Filter
          </Button>
        </div>
      ) : (
        <Filters
          alert={alert}
          activeJobFilters={activeJobFilters || undefined}
          precomputedFilters={precomputedFilters}
          jobCount={jobs.count}
          authenticated={authenticated}
        />
      )}
      {jobs.count === 0 && (
        <div className="text-gray-300 text-center mt-24">
          <div className="mb-6">No jobs found. Try a different filter!</div>
          <Button to="/" variant="outlined" className="">
            Reset filters
          </Button>
        </div>
      )}
      <div className="mt-6" />
      <PaginatedJobsTable
        authenticated={authenticated || false}
        size={size}
        count={jobs.count}
        jobs={jobs.jobs || []}
      />
      {!authenticated && (
        <div className="w-full">
          <FAQ />
        </div>
      )}
      {jobs.count > 0 && (
        <button
          onClick={() => window.scrollTo(0, 0)}
          className="mt-24 mx-auto text-gray-300 hover:text-lime-400 flex flex-col gap-4"
        >
          <ChevronUpIcon className="h-6 w-6 animate-bounce mx-auto" />
          Go back up
        </button>
      )}
    </>
  );
}
